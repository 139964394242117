import React from "react";
import { Typography } from "antd";

import "../../Style/footer.scss";

const { Text } = Typography;

export default function BotFooter() {
  const currentYear = new Date().getFullYear();
  return (
    <div>
      <Text>AGCD © {currentYear} Tam Chau</Text>
    </div>
  );
}
