import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, Button, Spin, notification } from "antd";
import { UserOutlined, LockOutlined, LoadingOutlined } from "@ant-design/icons";

import { firebaseApp, googleProvider } from "../../firebase";
import { PASSWORD_REGEX } from "../../Utils/Constant";

import "../../Style/auth.scss";

export default function Register() {
  const [state, setState] = useState({
    email: null,
    redirect: false,
    loading: false,
    googleLoading: false,
  });

  const onFinish = async (values) => {
    setState({ ...state, loading: true });
    firebaseApp
      .auth()
      .createUserWithEmailAndPassword(values.email, values.password)
      .then((res) => {
        if (res) {
          res.user.updateProfile({ displayName: values.name });
          notification.success({
            message: "Succesfully logged in!",
            description: "Logged in successfully, Redirecting you in a few!",
            placement: "topRight",
            duration: 1.5,
          });
          setState({ ...state, loading: false });
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message,
          placement: "topRight",
        });
        setState({ ...state, loading: false });
        console.log("error", error);
      });
  };

  const handleGoogleSignin = () => {
    setState({ ...state, loading: true });
    firebaseApp.auth().signInWithPopup(googleProvider);
  };

  return (
    <>
      <Form name="signup_form" className="signup-form" onFinish={onFinish}>
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Please input your name" }]}
        >
          <Input
            placeholder="Name"
            prefix={<UserOutlined className="iconButton" />}
          />
        </Form.Item>

        <Form.Item
          className="email"
          name="email"
          rules={[
            { type: "email", message: "The input is not valid E-mail" },
            { required: true, message: "Please input your E-mail" },
          ]}
        >
          <Input
            placeholder="Email"
            prefix={<UserOutlined className="iconButton" />}
          />
        </Form.Item>

        <Form.Item
          className="password"
          name="password"
          rules={[
            { required: true, message: "Please input your password" },
            {
              pattern: new RegExp(PASSWORD_REGEX),
              message:
                "Password must have a minimum length of 8 character and contain atleast 1 number",
            },
          ]}
          hasFeedback
        >
          <Input.Password
            placeholder="Password"
            prefix={<LockOutlined className="iconButton" />}
          />
        </Form.Item>

        <Form.Item
          className="confirmPassword"
          name="confirmPassword"
          dependencies={["password"]}
          hasFeedback
          rules={[
            { required: true, message: "Please confirm your password!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The two passwords you entered do not match!"
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Confirm Password"
            prefix={<LockOutlined className="iconButton" />}
          />
        </Form.Item>

        <div className="signin-link">
          <Link to="/signin">Login to your account</Link>
        </div>

        <Form.Item className="signup-button">
          <Button
            className="button"
            type="primary"
            disabled={state.loading}
            htmlType="submit"
          >
            {state.loading ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              "Sign Up"
            )}
          </Button>
        </Form.Item>

        <div className="or">Or</div>
      </Form>
      <div className="signin-google">
        <Button
          type="primary"
          disabled={state.googleLoading}
          className="button"
          onClick={handleGoogleSignin}
        >
          {state.googleLoading ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            "Sign In with Google"
          )}
        </Button>
      </div>
      {state.redirect && <Redirect to={{ pathname: "/" }} />}
    </>
  );
}
