import React, { useContext } from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import { Layout } from "antd";

import { ContextStore } from "../Context/ContextStore";
import TopHeader from "../Components/Header";
import BotFooter from "../Components/Footer";
import Home from "../Components/Home";
import Signin from "../Components/Authentication/Signin";
import Signup from "../Components/Authentication/Signup";
import Profile from "../Components/Profile";
import Management from "../Components/Management";

const { Header, Footer, Content } = Layout;

const AdminRoute = ({ component: Component, ...rest }) => {
  const { userData } = useContext(ContextStore);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!userData.isAuthenticated || !userData.isAdmin) {
          return <Redirect to="/" />;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

const AuthRoute = ({ component: Component, ...rest }) => {
  const { userData } = useContext(ContextStore);
  return (
    <Route
      {...rest}
      render={(props) => {
        return userData.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};

const UnauthRoute = ({ component: Component, ...rest }) => {
  const { userData } = useContext(ContextStore);
  return (
    <Route
      {...rest}
      render={(props) => {
        return !userData.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};

export default function Routes() {
  return (
    <BrowserRouter>
      <Layout>
        {/* <Header>
          <TopHeader />
        </Header> */}
        <Switch>
          <Content>
            <Route exact path="/" component={Home} />
            <UnauthRoute exact path="/signin" component={Signin} />
            <UnauthRoute exact path="/signup" component={Signup} />
            <AuthRoute exact path="/profile" component={Profile} />
            <AdminRoute exact path="/manage" component={Management} />
          </Content>
        </Switch>
        {/* <Footer>
          <BotFooter />
        </Footer> */}
      </Layout>
    </BrowserRouter>
  );
}
