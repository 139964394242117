import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Select, Button } from "antd";

import { db } from "../../firebase";
import { ContextStore } from "../../Context/ContextStore";

import logo from "../../Assets/logo.png";
import "../../Style/home.scss";

const { Option } = Select;

export default function Home() {
  const { userData, setUserData } = useContext(ContextStore);
  const history = useHistory();
  const [showBox, setShowBox] = useState(false);
  const [table, setTable] = useState();

  const districtList = [
    { name: "Quận 1", value: "01" },
    { name: "Quận 2", value: "02" },
    { name: "Quận 3", value: "03" },
    { name: "Quận 4", value: "04" },
    { name: "Quận 5", value: "05" },
    { name: "Quận 6", value: "06" },
    { name: "Quận 7", value: "07" },
    { name: "Quận 8", value: "08" },
    { name: "Quận 9", value: "09" },
    { name: "Quận 10", value: "10" },
    { name: "Quận 11", value: "11" },
    { name: "Quận 12", value: "12" },
    { name: "Quận Tân Bình", value: "qtb" },
    { name: "Quận Phú Nhuận", value: "qpn" },
    { name: "Quận Bình Thạnh", value: "qbt" },
    { name: "Quận Gò Vấp", value: "qgv" },
    { name: "Quận Tân Phú", value: "qtp" },
    { name: "Quận Bình Tân", value: "qbtan" },
    { name: "Quận Thủ Đức", value: "qtd" },
  ];

  const handleQuanAn = () => {
    if (userData.isAuthenticated) {
      db.collection("privateQuanAn")
        .get()
        .then((res) => {
          const data = res.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setShowBox(true);
          setTable(data);
        });
    } else {
      db.collection("publicQuanAn")
        .get()
        .then((res) => {
          const data = res.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setShowBox(true);
          setTable(data);
        });
    }
  };

  const [form] = Form.useForm();
  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        console.log("values", values);
      })
      .catch((errorInfo) => {});
  };

  return (
    <div className="home">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="main-content">
        <p className="text">
          Ứng dụng nên đc sử dụng khi bạn có bé bồ hay trả lời
          <span> "Ăn gì cũng đc"</span>
          <br />
          để tránh rạn nứt tình cảm lứa đôi :))
        </p>
        <Form className="form" form={form}>
          <Form.Item
            className="form-shop"
            name="shop"
            rules={[{ required: true, message: "Vui lòng chọn quán" }]}
          >
            <Select placeholder="Ăn gì?" allowClear>
              <Option value="quanAn">Quán ăn</Option>
              <Option value="quanChay">Quán chay</Option>
              <Option value="quanAnVat">Quán ăn vặt</Option>
              <Option value="quanNuoc">Quán nước</Option>
            </Select>
          </Form.Item>
          <Form.Item
            className="form-district"
            name="district"
            rules={[{ required: true, message: "Vui lòng chọn quận" }]}
          >
            <Select placeholder="Ở đâu?" allowClear>
              {districtList.map((item, idx) => {
                return (
                  <Option key={idx} value={item.value}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
        <div className="button-wrapper">
          <Button className="submit-button" onClick={onFinish}>
            Tìm kiếm ngay
          </Button>
        </div>
      </div>
      <div className="bottom">
        <p className="text">
          Để sử dụng tính năng thêm các địa điểm yêu thích của riêng bạn vào
          list chọn vui lòng
        </p>
        <Button
          shape="round"
          className="button"
          onClick={() => {
            history.push("/signup");
          }}
        >
          Đăng ký
        </Button>
        <Button
          shape="round"
          className="button button-right"
          onClick={() => {
            history.push("/signin");
          }}
        >
          Đăng nhập
        </Button>
      </div>
    </div>
  );
}
