import React, { useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Spin, Input, Button, notification } from "antd";
import { UserOutlined, LockOutlined, LoadingOutlined } from "@ant-design/icons";

import { firebaseApp, googleProvider } from "../../firebase";
import { ContextStore } from "../../Context/ContextStore";

import "../../Style/auth.scss";

export default function LoginContainer() {
  const [state, setState] = useState({
    redirect: false,
    loading: false,
    googleLoading: false,
  });
  const { userData, setUserData } = useContext(ContextStore);

  const onFormFinish = async (values) => {
    setState({ ...state, loading: true });
    firebaseApp
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then(() => {
        notification.success({
          message: "Succesfully logged in!",
          description: "Logged in successfully, Redirecting you in a few!",
          placement: "topRight",
          duration: 1.5,
        });
        setState({ ...state, loading: false });
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message,
          placement: "topRight",
        });
        setState({ ...state, loading: false });
        console.log("error", error);
      });
  };

  const handleGoogleSignin = () => {
    setState({ ...state, loading: true });
    firebaseApp.auth().signInWithPopup(googleProvider);
  };

  return (
    <>
      <Form name="signin_form" className="signin-form" onFinish={onFormFinish}>
        <Form.Item
          className="email"
          name="email"
          rules={[{ required: true, message: "Please input your Username!" }]}
        >
          <Input
            prefix={<UserOutlined className="iconButton" />}
            placeholder="Email"
          />
        </Form.Item>

        <Form.Item
          className="password"
          name="password"
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input
            prefix={<LockOutlined className="iconButton" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <div className="register-or-forgot">
          <Link to="/signup">Register now</Link>
          <Link to="/forgot">Forgot password</Link>
        </div>

        <Form.Item className="signin-button">
          <Button
            type="primary"
            disabled={state.loading}
            htmlType="submit"
            className="button"
          >
            {state.loading ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              "Sign In"
            )}
          </Button>
        </Form.Item>

        <div className="or">Or</div>
      </Form>

      <div className="signin-google">
        <Button
          type="primary"
          disabled={state.googleLoading}
          className="button"
          onClick={handleGoogleSignin}
        >
          {state.googleLoading ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            "Sign In with Google"
          )}
        </Button>
      </div>
      {state.redirect && <Redirect to={{ pathname: "/" }} />}
    </>
  );
}
