import Routes from "./Routes/Routes";
import "./Style/app.scss";

function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
