import React, { createContext, useState, useEffect } from "react";

import { firebaseApp } from "../firebase";

const ContextDefaultValue = {
  userData: {
    id: null,
    email: null,
    name: null,
    isAuthenticated: false,
    isAdmin: false,
  },
};

const ContextStore = createContext(ContextDefaultValue);

const ContextProvider = ({ children }) => {
  const [state, setState] = useState(ContextDefaultValue.userData);

  useEffect(() => {
    const checkAuth = () => {
      firebaseApp.auth().onAuthStateChanged(function (user) {
        if (user) {
          setState((preState) => ({
            ...preState,
            id: user.uid,
            email: user.email,
            name: user.displayName,
            isAuthenticated: true,
          }));
          if (
            user.email === "thanhtamag@gmail.com" ||
            user.email === "hoaitrang2134@gmail.com"
          ) {
            setState((preState) => ({
              ...preState,
              isAdmin: true,
            }));
          }
        }
      });
    };
    checkAuth();
  }, [state.isAuthenticated, state.isAdmin]);

  return (
    <ContextStore.Provider value={{ userData: state, setUserData: setState }}>
      {children}
    </ContextStore.Provider>
  );
};

export { ContextStore, ContextProvider };
