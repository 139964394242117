import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Table,
  Tooltip,
  Modal,
  Form,
  Spin,
  Input,
  Button,
  Select,
} from "antd";
import {
  LoadingOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { db } from "../../firebase";
import { ContextStore } from "../../Context/ContextStore";

const { Title } = Typography;
const { Option } = Select;

export default function Management() {
  const { userData, setUserData } = useContext(ContextStore);
  const [isLoading, setIsLoading] = useState(false);
  const [quanAn, setQuanAn] = useState();
  const [quanNuoc, setQuanNuoc] = useState();
  const [modalSubmitVisible, setModalSubmitVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);

  const handleDelete = (values) => {};

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Link",
      dataIndex: "link",
    },
    {
      title: "Action",
      width: "10%",
      align: "center",
      render: (record) => (
        <>
          <Tooltip title="edit">
            <EditOutlined
              style={{ cursor: "pointer", padding: "0 1vh" }}
              onClick={() => {
                setModalEditVisible(true);
              }}
            />
          </Tooltip>
          <Tooltip title="delete">
            <DeleteOutlined
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleDelete(record);
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const dataQuanAn = [
    {
      key: "1",
      name: 32,
      link: "New York No. 1 Lake Park",
    },
    {
      key: "2",
      name: 42,
      link: "London No. 1 Lake Park",
    },
    {
      key: "3",
      name: 32,
      link: "Sidney No. 1 Lake Park",
    },
  ];

  const dataQuanNuoc = [
    {
      key: "1",
      name: 32,
      link: "New York No. 1 Lake Park",
    },
    {
      key: "2",
      name: 42,
      link: "London No. 1 Lake Park",
    },
    {
      key: "3",
      name: 32,
      link: "Sidney No. 1 Lake Park",
    },
  ];

  const onFormFinish = async (values) => {
    setIsLoading(true);
    if (values.category === "quanAn") {
      db.collection("privateQuanAn")
        .add({
          owner: userData.id,
          link: values.link,
          name: values.name,
        })
        .then(() => {
          setIsLoading(false);
        });
    }
    if (values.category === "quanNuoc") {
      db.collection("privateQuanNuoc")
        .add({
          owner: userData.id,
          link: values.link,
          name: values.name,
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <Title level={5}>Danh sách quán ăn</Title>
      <Table columns={columns} dataSource={dataQuanAn} pagination={false} />
      <br />
      <Title level={5}>Danh sách quán nước</Title>
      <Table columns={columns} dataSource={dataQuanNuoc} pagination={false} />
      <Button
        type="primary"
        onClick={() => {
          setModalSubmitVisible(true);
        }}
      >
        Add new
      </Button>
      <Modal
        visible={modalSubmitVisible}
        onOk={onFormFinish}
        onCancel={() => {
          setModalSubmitVisible(false);
        }}
      >
        <Form
          name="add-link_form"
          className="add-link-form"
          onFinish={onFormFinish}
        >
          <Form.Item
            className="link"
            name="link"
            rules={[
              {
                required: true,
                message: "Please input link or shop's address",
              },
            ]}
          >
            <Input placeholder="Link or Shop's Address" />
          </Form.Item>

          <Form.Item
            className="name"
            name="name"
            rules={[{ required: true, message: "Please input shop's name" }]}
          >
            <Input placeholder="Shop's Name" />
          </Form.Item>

          <Form.Item
            className="category"
            name="category"
            rules={[{ required: true, message: "Please select category" }]}
          >
            <Select
              placeholder="Select shop category"
              // onChange={this.onGenderChange}
              allowClear
            >
              <Option value="quanAn">Quán ăn</Option>
              <Option value="quanNuoc">Quán nước</Option>
            </Select>
          </Form.Item>

          <Form.Item className="submit-button">
            <Button
              type="primary"
              disabled={isLoading}
              htmlType="submit"
              className="button"
            >
              {isLoading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                "Submit"
              )}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
